import './App.css';

function App() {
  return (
<html>
<head>
	<title>Lindsay Farm Design and Development</title>
	<link href='http://fonts.googleapis.com/css?family=PT+Sans' rel='stylesheet' type='text/css' />
	<link rel='shortcut icon' type='image/jpg' href='favicon.jpg' />

</head>
<body>
		<h1>Future Home of</h1>
		<img src="LF_logo.png" alt="logo" />
		<h1>Lindsay Farm Design and Development</h1>
		<h4>St. Johnsbury, Vermont</h4>
		<h2>Coming Soon.</h2>
</body>
</html>
  );
}

export default App;
